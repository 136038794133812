<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">MLA's Vidhan Parishad</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
            <DataTable :value="mlasvidhanparishadList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" paginator :totalRecords="totalRecords" :rows="30" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" >
            <template #empty>No Records found.</template>
            <template #loading>Loading data. Please wait...</template>
            <Column field="id" header="Sr." headerStyle="width: 5%" bodyStyle="width: 5%">
                <template #body="{ data }">
                    {{ data.id + 1 }}
                </template>
            </Column>
            <Column field="Name" header="MLA's Vidhan Parishad Name" headerStyle="width: 40%" bodyStyle="width: 40%">
                <template #body="{ data }">
                    <div>{{ data.label ? data.label : "N/A" }}</div>
                </template>
            </Column>
            <Column field="party" header="Party" headerStyle="width: 55%" bodyStyle="width: 55%">
                <template #body="{ data }">
                    <div>{{ data.sublabel ? data.sublabel : "N/A" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
    <script>

export default {
    data() {
        return {
            mlasvidhanparishadList: [
                {
                    id: 0,
                    label: "Pravin Darekar",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 1,
                    label: "Ram Shinde",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 2,
                    label: "Uma Khapre",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 3,
                    label: "Shrikant Bharatiya",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 4,
                    label: "Prasad Lad",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 5,
                    label: "Ranjitsinh Mohite Patil",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 6,
                    label: "Pravin Datke",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 7,
                    label: "Gopichand Padalkar",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 8,
                    label: "Ramesh Karad",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 9,
                    label: "Nilay Naik",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 10,
                    label: "Ram Patil Ratolikar",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 11,
                    label: "Ramesh Patil",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 12,
                    label: "Vijay Girkar",
                    sublabel: "Bharatiya Janata Party",
                },
                {
                    id: 13,
                    label: "Ramraje Naik Nimbalkar",
                    sublabel: "Nationalist Congress Party",
                },
                {
                    id: 14,
                    label: "Eknath Khadse",
                    sublabel: "Nationalist Congress Party",
                },
                {
                    id: 15,
                    label: "Shashikant Shinde",
                    sublabel: "Nationalist Congress Party",
                },
                {
                    id: 16,
                    label: "Amol Mitkari",
                    sublabel: "Nationalist Congress Party",
                },
                {
                    id: 17,
                    label: "Babajani Durani",
                    sublabel: "Nationalist Congress Party",
                },
                {
                    id: 18,
                    label: "Bhai Jagtap",
                    sublabel: "Indian National Congress",
                },
                {
                    id: 19,
                    label: "Rajesh Rathod",
                    sublabel: "Indian National Congress",
                },
                {
                    id: 20,
                    label: "Wajahat Ather Mirza",
                    sublabel: "Indian National Congress",
                },
                {
                    id: 21,
                    label: "Pradnya Rajeev Satav",
                    sublabel: "Indian National Congress",
                },
                {
                    id: 22,
                    label: "Sachin Ahir",
                    sublabel: "Shivsena",
                },
                {
                    id: 23,
                    label: "Aamshya Padavi",
                    sublabel: "Shivsena",
                },
                {
                    id: 24,
                    label: "Uddhav Thackeray",
                    sublabel: "Shivsena",
                },
                {
                    id: 25,
                    label: "Anil Parab",
                    sublabel: "Shivsena",
                },
                {
                    id: 26,
                    label: "Neelam Gorhe",
                    sublabel: "Shivsena",
                },
                {
                    id: 27,
                    label: "Manisha Kayande",
                    sublabel: "Shivsena",
                },
                {
                    id: 28,
                    label: "Jayant Patil",
                    sublabel: "Peasants and Workers Party of India",
                },
                {
                    id: 29,
                    label: "Mahadev Jankar",
                    sublabel: "Revolutionary Socialist Party",
                },

            ],
            totalRecords: 30,
        };
    },
};
</script>